import styled, {css} from "styled-components";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";

const handleGridColumn = gridColumn => {
    switch (gridColumn) {
        case 1:
            return "span 1";
        case 2:
            return "span 2";
        case 3:
            return "span 3";
        case 4:
            return "span 4";
        case 5:
            return "span 5";
        case 6:
            return "span 6";
        case 7:
            return "span 7";
        case 8:
            return "span 8";
        case 9:
            return "span 9";
        case 10:
            return "span 10";
        case 11:
            return "span 11";
        default:
            return "1/-1";
    }
};

export const FormImg = styled.img`
  width: 100%;
  border: 1px solid var(--body-text);
  box-sizing: border-box;
  margin-bottom: 10px;
`

export const Input = styled(TextField)`
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
  height: ${props => props.multiline ? "auto" : "48px"};
  grid-column: ${({span}) => handleGridColumn(span)};

  .MuiInputBase-root,
  .MuiFormLabel-root {
    color: var(--body-text);
  }

  .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid #a9c6ea;
      border-radius: 0;
    }

    &:hover fieldset {
      border-color: var(--primary-brand);
    }
  }

  @media screen and (max-width: 500px) {
    grid-column: 1/-1;
  }
`

const InputCss = css`
  font-family: 'Raleway', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  padding: 10px;
  height: 2.5rem !important;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  color: rgba(5, 12, 64, 0.3);
  border-radius: 3px;
  border: none;
  outline: none;
  box-sizing: border-box;
  grid-column: ${({span}) => handleGridColumn(span)};

  @media screen and (max-width: 500px) {
    grid-column: 1/-1;
  }
`

export const InputNew = styled.input`
  ${InputCss};
  transition: box-shadow 0.3s, border 0.3s, color 0.3s;

  &:focus {
    box-shadow: 0px 0px 5px #61C5FA;
    border: 1px solid #5AB0DB;
    color: #050C40;
  }

  &:focus:hover {
    box-shadow: 0px 0px 5px #61C5FA;
    border: 1px solid #5AB0DB;
  }
`

export const InputNewCb = styled.input`
  padding: 0 !important;
  height: unset !important;
  transform: scale(1.4);
  border: none;
  border-radius: 3px;
  outline: none;
`


export const SelectNew = styled.select`
  ${InputCss};
  color: #050C40;

  option {
    background-color: var(--white) !important;
    width: 100%;
  }
`

export const CheckboxWrapperNew = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  align-items: center;
  justify-content: start;
  margin-bottom: 1.25rem;
  grid-gap:0 1rem;
  width: 100%;
`

export const FormNew = styled.form`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1.25rem;
  max-width: 590px;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
`

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 24px;
`

export const CheckboxWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  align-items: center;
  justify-content: start;
  justify-items: start;
  width: 100%;
  height: 25px;
`

export const CheckboxForm = styled(Checkbox)`
  .MuiSvgIcon-root {
    color: var(--body-text);
  }
`

//BENEFITS FORM MEZŐI
export const FormBenefitsWrapper = styled.div`
  align-self: stretch;
  background: transparent;
  border-left: 5px solid #00aeef;
  border-radius: 0;
  padding-top: 20px;
  padding-bottom: 20px;
`
export const FormBenefits = styled.form`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  background: #113e6c;
  padding: 40px;
  @media screen and (max-width: 800px) {
    grid-gap: 10px;
    padding: 20px;
  }
`

export const SelectForm = styled(FormControl)`
  font-family: "Raleway", sans-serif !important;
  font-size: 1.2rem;
  height: ${props => props.autoheight ? "auto" : "48px"};
  grid-column: ${({span}) => handleGridColumn(span)};

  .MuiInputBase-root,
  .MuiFormLabel-root {
    color: var(--body-text);
  }

  .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid var(--body-text) !important;
      border-radius: 0;
    }

    &:hover fieldset {
      border-color: var(--primary-brand) !important;
    }

    svg {
      color: var(--body-text);
    }
  }

  .PrivateNotchedOutline-root-1 {
    border: 1px solid var(--body-text) !important;
    border-radius: 0;
  }

  .Mui-disabled {
    color: var(--body-text) !important;
    opacity: 0.5;

    & fieldset {
      border: 1px solid #a9c6ea;
      border-radius: 0;
    }

    &:hover fieldset {
      border: 1px solid #a9c6ea;
      border-radius: 0;
    }

    svg {
      color: var(--body-text);
    }
  }

  option {
    background-color: var(--secondary-brand) !important;
  }

  @media screen and (max-width: 500px) {
    grid-column: 1/-1;
  }
`

export const SelectContentLibrary = styled(FormControl)`
  font-family: "Raleway", sans-serif !important;
  font-size: 1.2rem;
  height: ${props => props.autoheight ? "auto" : "48px"};
  grid-column: ${({span}) => handleGridColumn(span)};

  .MuiInputBase-root,
  .MuiFormLabel-root {
    color: var(--body-text);
  }

  .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid rgba(13, 65, 121, 0.5) !important;
      border-radius: 0;
    }

    &:hover fieldset {
      border-color: var(--primary-brand) !important;
    }

    svg {
      color: var(--body-text);
    }
  }

  .PrivateNotchedOutline-root-1 {
    border: 1px solid rgba(13, 65, 121, 0.5) !important;
    border-radius: 0;
  }

  .Mui-disabled {
    color: var(--body-text) !important;
    opacity: 0.5;

    & fieldset {
      border: 1px solid #a9c6ea;
      border-radius: 0;
    }

    &:hover fieldset {
      border: 1px solid #a9c6ea;
      border-radius: 0;
    }

    svg {
      color: var(--body-text);
    }
  }

  option {
    background-color: var(--secondary-brand) !important;
  }

  @media screen and (max-width: 500px) {
    grid-column: 1/-1;
  }
`